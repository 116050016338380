import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routerList=[
  {
    path: '/',
    name: 'homeIndex',
    meta: { title: '首页' },
    component: (resolve) => require(['@/views/home/index.vue'], resolve),
    hidden: true
  }
]

export default new Router({
  // mode: 'hash',
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: routerList
})